<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">
           <h3>{{ store_coupons.stor_co_codigo }}</h3>
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <h2>{{ store_coupons.prod_nome }}</h2>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey" dark class="mb-2" :to="{name: 'store-coupons'}">
                <v-icon dark>keyboard_arrow_left</v-icon> Voltar
            </v-btn>
        </template>
    </v-toolbar>

    <table id="table-detalhe" class="table table-bordered table-condensed">
        <tbody>
            <tr>
                <th class="texto" width="10%">Tipo de Cupom:</th>
                <td width="90%" colspan="4">{{ store_coupons.stor_co_tipo_de_cupom }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Nome:</th>
                <td width="90%" colspan="4">{{ store_coupons.stor_co_nome }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Código Produto:</th>
                <td width="90%" colspan="4">{{ store_coupons.stor_co_codigo }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Tipo de Desconto:</th>
                <td width="40%" colspan="2">{{ (store_coupons.stor_co_tipo_desconto == 'P') ? 'PORCENTAGEM' : 'REAL' }}</td>
                <th class="texto" width="10%">Valor Desconto:</th>
                <td width="40%" colspan="2">{{ store_coupons.stor_co_valor_desconto }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Data Início:</th>
                <td width="40%" colspan="2">{{ store_coupons.stor_co_data_inicio }}</td>
                <th class="texto" width="10%">Data Término:</th>
                <td width="40%" colspan="2">{{ store_coupons.stor_co_data_fim }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Quantidade:</th>
                <td width="40%" colspan="2">{{ store_coupons.stor_co_quantidade }}</td>
                <th class="texto" width="10%">Utilizados:</th>
                <td width="40%" colspan="2">{{ store_coupons.stor_co_usados }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Valor acima de:</th>
                <td width="90%" colspan="4">{{ store_coupons.stor_co_valor_acima_de }}</td>
            </tr>
            <tr>
                <th class="texto pt-8" width="10%">Status:</th>
                <td width="90%" colspan="4"><v-switch color="lime" v-model="store_coupons.stor_co_status" class="my-switch" disabled></v-switch></td>
            </tr>

        </tbody>
    </table>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    name: "DetailProductComponent",
    created() {
        this.loadProduct()
    },
    props: {
        stor_co_id: {
            require: true
        }
    },
    data() {
        return {
            store_coupons: {},
            base_url: URL_BASE
        }
    },
    methods: {
        loadProduct() {
            this.$store.dispatch('loadStoreCoupon', this.stor_co_id)
                .then(response => {
                    this.store_coupons = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    }
};
</script>

<style scoped>
.avatar {
    height: 25px;
}

.logo {
    height: 50px;
}

.table th.texto {
    vertical-align: top;
}
</style>
